<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  async created() {

  },
  methods: {

  },
  components: {

  }
}
</script>

<style>

</style>
