import Main from './pages/Main'
import MyReward from './pages/MyReward'
import Detail from './pages/Detail'


export default [
    {
        path: "/",
        component: Main,
        meta: {
            title: "口袋机签到领蔬菜活动"
        },
    },
    {
        path: "/my",
        component: MyReward,
        meta: {
            title: "我的奖励"
        },
    },
    {
        path: "/detail",
        component: Detail,
        meta: {
            title: "详情"
        },
    },
]