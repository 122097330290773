
const appId = "wx5eb80641fae2b113"

export const AUTH_TYPE = {
    BASE: "snsapi_base",
    USER: "snsapi_userinfo"
}


export function gotoAuth(url, authType, state) {
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=${authType}&state=${state}#wechat_redirect`
}

export function getToken() {
    return localStorage.getItem('userToken') || ""
}

