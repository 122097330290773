<template>
  <div v-if="loading" class="app-loading">
    <div class="spinner">
      <div class="spinner-container container1">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
      <div class="spinner-container container2">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
      <div class="spinner-container container3">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
    </div>
  </div>
  <div v-else><!---->
    <div class="ur-wrap ur-detail">
      <header class="header"></header>
      <main class="main">
        <figure class="img">
          <img :src="reward.ticketImg">
        </figure>
        <h2 class="title">{{reward.rewardName}}</h2><!----><!----><!----><!---->
        <div class="hr"></div><!----><!----><!----><!----><!----><!----><!---->
        <div>
          <div class="sub-title">奖品说明</div>
          <div class="sub-desc" v-html="reward.rewardIntro?.replace(/\s+/g, '') || ''">
          </div>
        </div>
      </main>
      <a href="/" class="button-back router-link-active">返回首页</a></div><!----><!---->
    <div v-if="false" class="van-overlay ur-mask" type="bkkq" style="display: none">
      <div class="prize-form">
        <div class="title">领奖信息</div>
        <div class="detail"><!----><!---->
          <div class="item phone"><input type="tel" name="mobile" placeholder="请填写您的手机号码" class="input"></div>
          <div class="item code"><input type="text" name="captcha" placeholder="请填写验证码" class="input"><img
              src="/captcha/flat?_=1636525712533"></div>
          <div class="item pwd"><input type="text" name="code" placeholder="请填写短信验证码" class="input"><a
              class="van-count-down">获取验证码</a></div>
        </div>
        <input type="button" value="立即提交" class="button-submit"></div>
      <div class="sub_close"><span class="ur-close"></span></div>
    </div>
  </div>
</template>

<script>
import service from '../service'
import { getToken } from '../common'

export default {
  name: "Detail",
  data() {
    return {
      reward: {},
      loading: true,
    }
  },
  async created() {
    var result = await service.rewardDetail(getToken(), this.$route.query.ticket)
    this.reward = result.data
    this.loading = false
  }
}
</script>

<style>

.ur-wrap {
  min-height: 100vh;
  position: relative
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0
}


.e-e {
  overflow: hidden;
  font-size: 0;
}

.e-e img {
  width: 100%;
  object-fit: scale-down;
}

img {
  border-style: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

.ur-detail {
  background: #f5f4f8;
  min-height: 100vh;
  overflow-x: hidden
}

.ur-detail .header {
  background: #bc9c78;
  height: 1.9rem;
  border-radius: 50%;
  transform: scale(1.4) translateY(-.4rem)
}

.ur-detail .main {
  width: 3.3rem;
  background: #fff;
  box-shadow: 0 2px .04rem 0 rgba(0, 0, 0, .18);
  margin: -1rem auto .3rem;
  position: relative;
  border-radius: .06rem;
  padding-top: .6rem
}

.ur-detail .main .img {
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  position: absolute;
  top: -.5rem;
  left: 0;
  right: 0;
  margin: auto
}

.ur-detail .main .hr {
  height: .14rem;
  margin: .2rem auto;
  background-color: #f5f4f8
}

.ur-detail .main:after {
  content: " ";
  display: block;
  background-size: 3.4rem auto;
  height: .2rem;
  position: relative;
  bottom: -.1rem
}

.ur-detail .main .title {
  font-size: .2rem;
  color: #333;
  text-align: center;
  margin: .15rem
}

.ur-detail .main .desc {
  font-size: .14rem;
  color: #666;
  line-height: .28rem;
  position: relative;
  text-indent: -3em;
  width: 1.6rem;
  margin-left: 1rem
}

.ur-detail .main .desc .copy {
  position: absolute;
  font-size: .1rem;
  text-align: center;
  color: #666;
  width: .32rem;
  height: .16rem;
  line-height: .16rem;
  border-radius: 2px;
  border: 1px solid #ddd;
  transform: translateX(.1rem);
  top: .04rem;
  right: -.32rem;
  text-indent: 0
}

.ur-detail .main .sub-title {
  font-size: .14rem;
  color: #999;
  padding: .1rem .2rem;
  margin-top: .15rem
}

.ur-detail .main .url {
  font-size: .14rem;
  color: #bc9c78;
  padding: .05rem .2rem;
  display: block
}

.ur-detail .main .sub-desc {
  font-size: .14rem;
  color: #555;
  padding: .05rem .2rem;
  line-height: 1.5;
  white-space: pre-wrap
}

.ur-detail .btn {
  height: .4rem;
  width: 2.2rem;
  margin: 0 auto .15rem;
  font-size: .16rem;
  border-radius: .04rem;
  background: linear-gradient(225deg, #ff4c29, #f03633);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700
}

.ur-detail .button-back {
  position: fixed;
  width: .56rem;
  height: .56rem;
  right: .26rem;
  bottom: .26rem;
  background: rgba(0, 0, 0, .5);
  box-shadow: 0 .03rem .04rem 0 rgba(0, 0, 0, .15);
  border-radius: 50%;
  color: #fff;
  font-size: .14rem;
  z-index: 100;
  text-align: center;
  box-sizing: border-box;
  padding: .12rem .1rem;
  line-height: .16rem
}

.ur-mask {
  flex-flow: column
}

.ur-mask, .ur-mask .close, .ur-mask .sub_close {
  display: flex;
  align-items: center;
  justify-content: center
}

.ur-mask .close, .ur-mask .sub_close {
  width: .35rem;
  height: .35rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, .5);
  margin-top: .16rem
}

.ur-mask .sub_close {
  position: absolute;
  top: 4.5rem
}

.ur-mask .result {
  position: relative;
  width: 2.86rem;
  border-image: url(https://ccbhdimg.kerlala.com/hd/autographnew/ccb/m-bg.png) 260 0 40 fill;
  border-top: 1.3rem;
  border-bottom: .2rem;
  border-style: solid;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: .2rem
}

.ur-mask .result .title {
  font-size: .16rem;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: -.42rem
}

.ur-mask .result .btn {
  width: 1.6rem;
  line-height: .36rem;
  background: linear-gradient(-90deg, #ff1f27, #ff7e2c);
  box-shadow: 0 .03rem .08rem 0 rgba(255, 55, 24, .3);
  border-radius: .18rem;
  font-weight: 700;
  color: #fff;
  position: absolute;
  bottom: -.36rem
}

.ur-mask .result .content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  width: 2rem;
  margin-bottom: .2rem
}

.ur-mask .result .content .msg {
  font-size: .18rem;
  color: #333;
  margin: .16rem 0 .2rem
}

.ur-mask .result .content .img {
  height: .74rem
}

.ur-mask .result .content .desc {
  font-size: .11rem;
  color: #999;
  line-height: .15rem;
  padding-top: 1em
}

.ur-mask .result .hiht {
  margin-bottom: .05rem;
  line-height: .18rem;
  color: #ff782c;
  text-align: center
}

.ur-mask .rule {
  width: 3.25rem;
  height: 3.9rem;
  background: #fff;
  box-shadow: 0 .05rem .14rem 0 rgba(0, 0, 0, .25);
  border-radius: .04rem;
  text-align: left;
  position: relative
}

.ur-mask .rule .title {
  color: #383838;
  font-size: .18rem;
  text-align: center;
  font-weight: 700
}

.ur-mask .rule .content {
  font-size: .14rem;
  color: #555;
  line-height: .24rem
}

.ur-mask .rule .content .sub-title {
  margin: .2rem 0 .12rem;
  color: #fd9a1b
}

.ur-mask .rule .content .sub-title:before {
  content: " ";
  background: #fd9a1b;
  width: .04rem;
  height: .12rem;
  border-radius: 2px;
  opacity: .6;
  display: inline-block;
  margin-right: .06rem;
  vertical-align: middle
}

.ur-mask .rule .content .sub-title span {
  vertical-align: middle
}

.ur-mask .rule .content p {
  padding-left: .1rem
}

.ur-mask .rule .content p.desc {
  color: #383838;
  white-space: pre-line
}

.ur-mask .rule .content.content2 p {
  padding-left: 0
}

.ur-mask .rule .wrap {
  overflow: auto;
  padding: .3rem;
  height: 100%;
  box-sizing: border-box
}

.ur-mask .prize-form {
  padding: .4rem .3rem .3rem;
  background: #fff;
  width: 3rem;
  box-sizing: border-box;
  box-shadow: 0 .05rem .14rem 0 rgba(0, 0, 0, .25);
  border-radius: .04rem;
  position: absolute;
  top: 1.2rem
}

.ur-mask .prize-form .title {
  font-size: .18rem;
  font-weight: 700;
  text-align: center
}

.ur-mask .prize-form .detail {
  font-size: .14rem;
  font-weight: 700;
  margin-top: .26rem;
  text-align: left
}

.ur-mask .prize-form .detail .item {
  line-height: .5rem;
  border-bottom: 1px solid #eceef1
}

.ur-mask .prize-form .detail .item:before {
  content: " ";
  display: inline-block;
  width: .12rem;
  height: .14rem;
  vertical-align: middle;
  margin-right: .07rem
}

.ur-mask .prize-form .detail .item.code input {
  width: 1.2rem
}

.ur-mask .prize-form .detail .item.code img {
  width: .84rem;
  vertical-align: middle
}

.ur-mask .prize-form .detail .item.pwd input {
  width: 1.2rem
}

.ur-mask .prize-form .detail .item.pwd .van-count-down {
  width: .84rem;
  vertical-align: middle;
  color: #3988ff;
  display: inline-block
}

.ur-mask .prize-form .detail .item input {
  vertical-align: middle;
  border: none;
  line-height: .3rem;
  padding-left: .05rem;
  padding-right: .05rem;
  width: 2.04rem;
  outline: none
}

.ur-mask .prize-form .detail .item input::-webkit-input-placeholder {
  opacity: .8
}

.ur-mask .prize-form .button-submit {
  width: 100%;
  height: .4rem;
  line-height: .42rem;
  margin-top: .2rem;
  border-radius: .04rem;
  background: linear-gradient(270deg, #fda121, #f55551);
  color: #fff;
  font-size: .14rem;
  font-weight: 700
}

.ur-mask .prize-form .button-submit.disabled, .ur-mask .prize-form .button-submit:disabled {
  opacity: .5
}

.ur-mask .user-form {
  padding: .4rem .3rem .3rem;
  background: #fff;
  width: 3rem;
  box-sizing: border-box;
  box-shadow: 0 .05rem .14rem 0 rgba(0, 0, 0, .25);
  border-radius: .04rem;
  position: absolute;
  top: 1.2rem
}

.ur-mask .user-form .title {
  font-size: .18rem;
  font-weight: 700;
  text-align: center
}

.ur-mask .user-form .detail {
  font-size: .14rem;
  font-weight: 700;
  margin-top: .26rem;
  text-align: left
}

.ur-mask .user-form .detail .item {
  line-height: .5rem;
  border-bottom: 1px solid #eceef1
}

.ur-mask .user-form .detail .item:before {
  content: " ";
  display: inline-block;
  width: .12rem;
  height: .14rem;
  vertical-align: middle;
  margin-right: .07rem
}

.ur-mask .user-form .detail .item.code input {
  width: 1.2rem
}

.ur-mask .user-form .detail .item.code img {
  width: .84rem;
  vertical-align: middle
}

.ur-mask .user-form .detail .item.pwd input {
  width: 1.2rem
}

.ur-mask .user-form .detail .item.pwd .van-count-down {
  width: .84rem;
  vertical-align: middle;
  color: #3988ff;
  display: inline-block
}

.ur-mask .user-form .detail .item input {
  vertical-align: middle;
  border: none;
  line-height: .3rem;
  padding-left: .05rem;
  padding-right: .05rem;
  width: 2.04rem;
  outline: none
}

.ur-mask .user-form .detail .item input::-webkit-input-placeholder {
  opacity: .8
}

.ur-mask .user-form .tips {
  font-size: .12rem;
  color: #fc462d;
  text-align: center;
  font-weight: 400
}

.ur-mask .button-red {
  width: 100%;
  height: .38rem;
  line-height: .38rem;
  background: linear-gradient(225deg, #ff4c29, #f03633);
  border-radius: 2px;
  font-size: .14rem;
  color: #fff;
  margin-top: .15rem
}

.ur-mask .button-red.disabled, .ur-mask .button-red:disabled {
  background: linear-gradient(225deg, #ff5037, #fd2320);
  opacity: .5
}

.m-alert {
  border-radius: .04rem
}

.m-alert .van-dialog__footer {
  display: flex;
  justify-content: center
}

.m-alert .van-dialog__footer:after {
  content: none
}

.m-alert .van-button {
  color: #fff;
  background: #f05e4a;
  width: 1.6rem;
  height: .4rem;
  line-height: .4rem;
  border-radius: .2rem;
  margin-bottom: .2rem
}
</style>