import axios from 'axios'
import { gotoAuth, AUTH_TYPE } from './common'

axios.interceptors.response.use(result => {
    console.log(result)
    if (result.data.code === 403) {
        // redirect login
        localStorage.removeItem("userToken")
        gotoAuth(location.href, AUTH_TYPE.USER, "")
        return;
    }
    if (result.data.code !== 1) {
        // 出错了
        alert(`出错了！\n${result.data.message}`)
        return result.data
    }
    return result.data
}, error => {
    console.log(error)
    return error
})

export default {
    getUserInfo: async (token) => {
        return await axios.get("/api/sign/user/info", {
            params: {
                token
            }
        })
    },
    login: async (code) => {
        return await axios.get("/api/sign/user/login", {
            params: {
                code
            }
        })
    },
    userSign: async (token) => {
        return await axios.get('/api/sign/user/signed', {
            params: {
                token
            }
        })
    },
    reward: async (token) => {
        return await axios.get('/api/sign/user/reward', {
            params: {
                token
            }
        })
    },
    rewardDetail: async (token, ticket) => {
        return await axios.get('/api/sign/user/reward/detail', {
            params: {
                token,
                ticket
            }
        })
    }
}


