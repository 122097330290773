<template>
  <div v-if="loading" class="app-loading">
    <div class="spinner">
      <div class="spinner-container container1">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
      <div class="spinner-container container2">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
      <div class="spinner-container container3">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
      </div>
    </div>
  </div>
  <div v-else>
    <aside class="e-e"><a
        href=""><img
        src="https://ccbhdimg.kerlala.com/hd/users/10431/20210903/2781181451.jpg" alt=""></a></aside>
    <div class="ur-wrap ur-my">
      <ul v-if="rewardList && rewardList.length > 0" class="list">
        <transition-group name="list" tag="p">
        <li v-for="(item, key) in rewardList" class="item" :key="key" @click="doDetail(item.ticket)" >
          <figure class="image">
            <img :src="item.ticketImg">
          </figure>
          <div class="info">
            <div class="title">
              <span class="txt">{{item.rewardName}}</span>
              <!---->
              <span v-if="item.status === 0" class="status issued">待发放</span>
              <span v-else-if="item.status === 1" class="status gray">已发放</span>
              <span v-else class="status gray">已过期</span>
<!--              <span class="status issued">发放中</span>-->
            </div>
            <div class="tips">
              <p>获得时间：{{formatDate(item.created)}}</p>
              <p>有效期到：{{item.expire}} 23:59:59</p>
            </div>
          </div>
          <span class="icon"></span></li>
        </transition-group>
      </ul>
      <div v-else class="empty">
        <div class="no-prize">
          <div class="title">您还没有奖品</div>
          <div class="tips">更多神秘大奖等你哦
            <router-link class="button-return" to="/">返回首页</router-link>
          </div>
        </div>
      </div>
    </div><!----><a href="/" class="fix-to-home router-link-active">返回首页</a></div>
</template>

<script>
import service from '../service'
import { getToken } from '../common'
import small from '../assets/small.jpg'

export default {
  name: "MyReward",
  data() {
    return {
      rewardList: [{}],
      loading: true,
      smallImg: small,
    }
  },
  async created() {
    const result = await service.reward(getToken())
    this.rewardList = result.data
    this.loading = false
  },
  methods: {
    formatDate(s) {
      var d = new Date(s);
      var y = d.getFullYear()
      var m = d.getMonth() + 1
      var dd = d.getDate()
      var h = d.getHours()
      var mm = d.getMinutes()
      var ss = d.getSeconds()
      return `${y}-${m > 9 ? m : '0' + m}-${dd > 9 ? dd : '0' + dd} ${h > 9 ? h : '0' + h}:${mm > 9 ? mm : '0' + mm}:${ss > 9 ? ss : '0' + ss}`
    },
    doDetail(ticket) {
      this.$router.push({
        path: '/detail',
        query: {
          ticket
        }
      })
    }
  }
}
</script>

<style>

.ur-wrap {
  min-height: 100vh;
  position:relative
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 0;
  height:0
}


.e-e {
  overflow: hidden;
  font-size: 0;
}

.e-e img {
  width: 100%;
  object-fit: scale-down;
}

img {
  border-style: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

.ur-my {
  min-height: 100vh;
  box-sizing: border-box;
  background: #bc9c78;
  padding:.16rem
}

.ur-my .list {
  margin:auto
}

.ur-my .list .item {
  height: 1.1rem;
  background: #fff;
  box-shadow: 0 0 .03rem 0 rgba(0, 0, 0, .2);
  border-radius: .04rem;
  margin-bottom: .1rem;
  position: relative;
  padding: .25rem 0 .25rem .15rem;
  box-sizing:border-box
}

.ur-my .list .item .image {
  width: .6rem;
  height: .6rem;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  display: inline-block;
  vertical-align:middle
}

.ur-my .list .item .info {
  display: inline-block;
  vertical-align: middle;
  margin-left:.15rem
}

.ur-my .list .item .info .title .txt {
  font-size: .18rem;
  font-weight: 700;
  color: #333;
  display: inline-block;
  margin: .08rem .08rem .12rem auto;
  vertical-align: middle;
  max-width:1.64rem
}

.ur-my .list .item .info .title .txt.long {
  max-width:2rem
}

.ur-my .list .item .info .title .status {
  height: .16rem;
  color: #f76f53;
  border: 1px solid rgba(247, 111, 83, .3);
  padding:2px .05rem
}

.ur-my .list .item .info .title .status.gray {
  color: #999;
  border:1px solid hsla(0, 0%, 60%, .3)
}

.ur-my .list .item .info .title .status.issued {
  color: #0cb059;
  border:1px solid hsla(0, 0%, 60%, .3)
}

.ur-my .list .item .info .tips {
  font-size: .12rem;
  color:#999
}

.ur-my .list .item .icon {
  position: absolute;
  right: .15rem;
  top: .46rem;
  width: .16rem;
  height:.16rem
}

.ur-my .empty {
  text-align:center
}

.ur-my .empty .no-prize {
  width: 2.77rem;
  height: 1.7rem;
  margin:.9rem auto .22rem
}

.ur-my .empty .title {
  font-size: .18rem;
  font-weight: 700;
  color: #fff6e9;
  margin-bottom:.1rem
}

.ur-my .empty .tips {
  font-size: .14rem;
  color:#ffde98
}

.ur-my .empty .button-return {
  width: 2.51rem;
  height: .48rem;
  font-size: .16rem;
  font-weight: 700;
  color: #902d00;
  line-height: .5rem;
  margin-top:.5rem
}
</style>